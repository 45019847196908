import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'

import BackgroundImage from 'gatsby-background-image'
import Layout from '../containers/layout'

export const query = graphql`
  query ThanksPageQuery {

    start: sanityBackgrounds {
      startPage {
        _key
        _type
        alt
        asset {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }


  }
`

const ThanksPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const start = (data || {}).start
  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (

    <Layout pageLayout='normal-footer'>
      <SEO title='404: Not found' />
      <article className='sections'>
        <section className='page-section white start'>

          <div className='mainImage hero--subpage hero'>
            <BackgroundImage
              Tag='section'
              style={{
                backgroundSize: 'cover',
                backgroundPosition: 'top center',
                backgroundRepeat: 'none',
                display: 'block',
                position: 'fixed',
                height: '100%',
                width: '100%'
              }}
              fluid={start.startPage.asset.fluid}
              backgroundColor={`#040e18`}
              fadeIn={false}
            />
            <div className='content-wrapper design-page'>
              <div className='content'>
                <div className='normal-sections-wrapper'>
                  <div className='subsection'>
                    <h1>Thank you</h1>
                    <p>We have received your email and will get back to you as soon as possible.</p>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </section>
      </article>
    </Layout>
  )
}

export default ThanksPage
